import React from "react"
import { Link } from "gatsby"
import {
  Row,
  Col,
  Container,
  Breadcrumb,
  Button,
  Carousel,
  Accordion,
  Card,
} from "react-bootstrap"

import Layout from "../components/layout"
import Seo from "../components/seo"

import LuteinComplexMobile from "./../images/Psoriazal-plus-mobile.jpg"
import LuteinComplexTablet from "./../images/Psoriazal-plus-tablet.jpg"
import LuteinComplexDesktop from "./../images/Psoriazal-plus-desktop.jpg"
import LuteinComplexXL from "./../images/Psoriazal-plus-desktop-xl.jpg"
import Feature1 from "./../images/psoriazal-plus-skin.jpg"
import Feature2 from "./../images/psoriazal-plus-sarsaparila.jpg"
import Feature3 from "./../images/psoriazal-plus-herbs.jpg"
import Feature4 from "./../images/prost-psor-plus-herbal-guarantee.jpg"
import Testimonial1 from "./../images/kalinka-stoyanova.jpg"
import Testimonial2 from "./../images/boicho-boichev.jpg"

const PsoriazalPlusPage = () => (
  <Layout backButton={true} pageInfo={{ pageName: "PsoriazalPlus" }}>
    <Seo title="Psoriazal Plus - Подкрепа на кожата при псориазис" />
    <Container className="p-0">
      <Row>
        <Col className="d-none d-md-block">
          <Breadcrumb>
            <li className="breadcrumb-item">
              <Link to="/">НАЧАЛНА СТРАНИЦА</Link>
            </li>
            <Breadcrumb.Item active>PSORIAZAL PLUS</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <picture>
            <source srcSet={LuteinComplexXL} media="(min-width: 1200px)" />
            <source srcSet={LuteinComplexDesktop} media="(min-width: 992px)" />
            <source srcSet={LuteinComplexTablet} media="(min-width: 767px)" />
            <source srcSet={LuteinComplexMobile} media="(min-width: 370px)" />
            <img
              srcSet={LuteinComplexMobile}
              alt="Psoriazal Plus"
              className="w-100 fluid rounded mb-4"
            />
          </picture>
        </Col>
        <Col xs={6} className="d-none d-lg-block large-heading">
          <h5 className="text-uppercase font-light letter-space-wide ">
            Psoriazal Plus
          </h5>
          <h1 className="display-4 font-light">
            Подкрепа на кожата при псориазис
          </h1>
        </Col>
      </Row>
      <Row className="mb-5 product-container">
        <Col sm={12} className="d-lg-none">
          <h4 className="text-uppercase mb-3 letter-space-wide">
            Psoriazal Plus
          </h4>
          <h1 className="display-5 font-light mb-4 large-heading-mobile">
            Подкрепа на кожата при псориазис
          </h1>
        </Col>
        <Col sm={12} md={6} className="mb-4">
          <h5 className="subhead font-light">
            Псориазал Плюс съдържа природни билки с доказани положителни
            свойства върху кожата и борбата с псориазиса.
          </h5>
        </Col>
        <Col sm={12} md={6}>
          <Col className="mb-4 p-0">
            <div>
              <a
                href="https://bemore.shop/bg/vh7-psoriazal-plus"
                target="blank"
                rel="nofollow"
                className="text-decoration-none"
              >
                <Button
                  variant="primary"
                  className="buy-button mx-auto d-block"
                >
                  Купете онлайн от <strong>BeMore.shop</strong>
                </Button>
              </a>
            </div>
          </Col>
          <Col className="mb-4 p-0">
            <div className="pharma-label mx-auto d-block">
              <p>Продава се в аптеките в цялата страна</p>
            </div>
          </Col>
          <Col className="mb-4 p-0">
            <div className="pharma-label mx-auto d-block">
              <ul>
                <li>без разтворители</li>
                <li>без консерванти</li>
                <li>без глутен</li>
                <li>без захар</li>
                <li>без ГМО</li>
              </ul>
            </div>
          </Col>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col>
          <h2 className="text-center">Вътрешна грижа за кожата</h2>
        </Col>
      </Row>
      <Row className="product-container mb-5">
        <Col md={6} className="order-md-1">
          <img
            srcSet={Feature1}
            alt="Вътрешна грижа за кожата"
            className="w-100 fluid rounded mb-4"
          />
        </Col>
        <Col md={6} className="order-md-0">
          <p>
            Псориазал Плюс подкрепя функциите и здравето на кожата. Растителните
            екстракти подпомагат кръвоснабдяването на кожата и подкрепят
            възстановителните ѝ функции. Активнодействащите субстанции
            упражняват комплексно въздействие върху кожата, като съдействат за
            тонизирането ѝ, за стабилизиране дейността на кръвоносните ѝ съдове,
            за подобряване обмяната на веществата и процесите на епителизация и
            регенерация.
          </p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h2 className="text-center">Подкрепа за кръвоснабдяването</h2>
        </Col>
      </Row>
      <Row className="product-container mb-5">
        <Col md={6}>
          <img
            srcSet={Feature2}
            alt="Подкрепа за кръвоснабдяването"
            className="w-100 fluid rounded mb-4"
          />
        </Col>
        <Col md={6}>
          <p>
            Ефективността на основнат съставка на Псориазал Плюс - екстракта от
            сарсапарила се дължи на съдържащите се в него голям брой биоактивни
            вещества като сапонини, стероиди и минерали, които подкрепят
            пречистващите функции на организма, стимулирайки отделянето на
            вредните вещества. Сарсапарилата притежава и силно изявено
            противоалергично, диуретично и хормоноактивиращо действие. Поради
            това освен при псориазис тя се прилага и при екземи, уртикария и
            косопад. Използва се също като имуностимулатор с общ тонизиращ
            ефект.
          </p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h2 className="text-center">Подбрани билки от природата</h2>
        </Col>
      </Row>
      <Row className="product-container mb-5">
        <Col md={6} className="order-md-1">
          <img
            srcSet={Feature3}
            alt="Подбрани билки от природата"
            className="w-100 fluid rounded mb-4"
          />
        </Col>
        <Col md={6} className="order-md-0">
          <p>
            Псориазал Плюс съдържа билкови екстракти от корен на Сарсапарила,
            листа на бреза, коприва, корени от репей и стръкове от камшик.
            Комбинация от благотворни активни съставки с положителен ефект върху
            кожата и псориазиса.
          </p>
          <p>
            Екстрактът от листата на бреза притежава силно дерматотонично,
            отводняващо и противомикробно действие. Екстрактът от репей е богат
            на активни компоненти, които ускоряват растежа на нови клетки в
            организма, в това число и на здрави кожни клетки. Екстрактът от
            коприва включва дъбилни и белтъчни вещества имащи дерматотоничен,
            противоалергичен, успокояващ, противосърбежен и антисклеротичен
            ефект. От своя страна екстрактът от камшик също действа
            противовъзпалително и притежава противомикробни свойства.
          </p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h2 className="text-center">Гаранция за качество и сигурност</h2>
        </Col>
      </Row>
      <Row className="product-container mb-5">
        <Col md={6}>
          <img
            srcSet={Feature4}
            alt="Гаранция за качество и сигурност"
            className="w-100 fluid rounded mb-4"
          />
        </Col>
        <Col md={6}>
          <p>
            Стандартизирани растителни екстракти се получават от растения или
            техни части чрез извличане на биологично активни компоненти. Тяхното
            преимущество е, че вида на активните вещества може да се контролира
            и поддържа в определено количество и качество. Приемът на екстракт с
            доказан ефект гарантира оптимално въздействие върху функциите на
            организма. Псориазал Плюс съдържа подбрани растителни екстракти с
            благоприятно действие при псориазис.
          </p>
        </Col>
      </Row>

      <Row className="product-container mb-2">
        <Col>
          <h5 className="section-heading">
            Мнения на специалисти
            <br />и потребители
          </h5>
        </Col>
      </Row>
      <Row className="mb-5 product-container">
        <Col>
          <Carousel
            indicators={false}
            fade={false}
            interval={4500}
            className="shadow-1 mx-auto testimonial-container"
          >
            <Carousel.Item>
              <Card className="testimonial">
                <Card.Img
                  className="d-block mx-auto"
                  variant="top"
                  src={Testimonial1}
                  alt="Калинка Стоянова"
                />
                <Card.Body>
                  <Card.Title>
                    <h5 className="font-light">
                      Подкрепа на кожата при псориазис
                    </h5>
                  </Card.Title>
                  <Card.Text className="h6-small">
                    Преди години след посещения при дерматолог, ми откриха
                    заболяване, а именно псориазис. Лекувах се дълги години с
                    различни медикаменти, но резултатите бяха временни или
                    въобще нямаше подобрение. Започнах да се отчайвам – имах
                    чувството, че никога няма да видя кожата си здрава, защото
                    нищо не ми помагаше.
                    <br />
                    <br />
                    Един ден се заговорих с моя стара позната, която се оказа
                    със същия проблем. Препоръча ми ПСОРИАЗАЛ ПЛЮС, който лично
                    тя е използвала и е постигнала дългоочакваните резултати.
                    Първоначално реагирах скептично на това, но въпреки всичко
                    реших да го пробвам. Още в самото начало дойде и
                    облекчаване. С всеки изминал ден усещах как засегнатата от
                    псориазис кожа се успокоява и симптомите намаляват, макар да
                    знам, че псориазиса не е напълно лечим. Консултирах се с
                    личния си лекар и споделих за подобрението, което съм
                    постигнала. От него научих, че една от билките в състава на
                    ПСОРИАЗАЛ ПЛЮС – „сарсапарила“ много добре действа на това
                    заболяване. Благодарение на ПСОРИАЗАЛ ПЛЮС мога да видя
                    кожата си отново здрава и красива.
                    <br />
                    <br />
                    Калинка Стоянова, Варна
                  </Card.Text>
                </Card.Body>
              </Card>
            </Carousel.Item>
            <Carousel.Item>
              <Card className="testimonial">
                <Card.Img
                  className="d-block mx-auto"
                  variant="top"
                  src={Testimonial2}
                  alt="Бойчо Бойчев"
                />
                <Card.Body>
                  <Card.Title>
                    <h5 className="font-light">Псориазал Плюс помага</h5>
                  </Card.Title>
                  <Card.Text className="h6-small">
                    Преди 5 години жена ми почина и преживях силен стрес. По
                    тялото ми започнаха да излизат червени петна, които много ме
                    сърбяха. Оказа се псориазис, който се от-ключил на нервна
                    почва. Лекуваха ме по болници, ходих на терапии, но след
                    време пак се появяваха петната. Лятото от слънцето и морето
                    се пооправях, но дойдеше ли есента псориазиса се появяваше
                    отново.
                    <br />
                    <br />
                    Един ден дъщеря ми дойде от София и ми донесе ПСОРИАЗАЛ ПЛЮС
                    – чела от някакво списание за него. Почнах да го пия сутрин
                    и вечер, както пише в листовката и видях на двадесетия ден,
                    че по-малките петънца изчезват. След месец и половина и
                    големите започнаха да се скриват, не ме сърбеше вече.
                    Постепенно се изчистих по цялото тяло. Продължавам и до ден
                    днешен да пия ПСОРИАЗАЛ ПЛЮС, нищо че по кожа-та нямам нищо.
                    Вярвам само на негo.
                    <br />
                    <br />
                    Бойчо Бойчев, Варна
                  </Card.Text>
                </Card.Body>
              </Card>
            </Carousel.Item>
          </Carousel>
        </Col>
      </Row>
      <Row className="mb-2 product-container">
        <Col>
          <h5 className="section-heading">Повече за Псориазал Плюс</h5>
        </Col>
      </Row>
      <Row className="mb-5 product-container">
        <Col>
          <Accordion defaultActiveKey="0" className="acc-style">
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="0"
                className="pointer"
              >
                Състав
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <p>
                    <strong>Сарсапарила</strong> – корени (Extr. Rad. Smilax
                    officinalis) 100 mg
                  </p>
                  <p>
                    <strong>Бреза</strong> – листа (Extr. Fol. Betulae albae) 75
                    mg
                  </p>
                  <p>
                    <strong>Коприва</strong> – листа (Extr. Fol. Urticae
                    dioicae) 25 mg
                  </p>
                  <p>
                    <strong>Репей</strong> – корен (Extr. Rad. Bardanae) 25 mg
                  </p>
                  <p>
                    <strong>Камшик</strong> – стръкове (Extr. Hrb. Agrimoniae)
                    25 mg
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="1"
                className="pointer"
              >
                Прием
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <p>
                    Псориазал Плюс се приема по 1 таблетка 2 пъти дневно след
                    хранене. Препоръчително е продължителността на приема да не
                    е по-малко от 3 месeца.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="2"
                className="pointer"
              >
                Противопоказания
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  <p>
                    Не са установени. Псориазал Плюс е хранителна добавка и не
                    предизвиква дразнене и свръхчувствителност и може да се
                    приема продължително време. Псориазал Плюс може да се приема
                    самостоятелно или в комбинация с предписани от лекуващия
                    лекар медикаменти.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="3"
                className="pointer"
              >
                Действие
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="3">
                <Card.Body>
                  <p>
                    Ефективността на екстракта от сарсапарила се дължи на
                    съдържащите се в него голям брой биоактивни вещества като
                    сапонини, стероиди и минерали, които подкрепят очистителните
                    функции на организма, стимулирайки отделянето на вредните
                    вещества от него. Сарсапарилата притежава и силно изявено
                    противоалергично, диуретично и хормоноактивиращо действие.
                    Поради това освен при псориазис тя се прилага и при екземи,
                    уртикария и косопад. Използва се също като имуностимулатор с
                    общ тонизиращ ефект.
                  </p>
                  <p>
                    Екстрактът от листата на бреза съдържа вещества, на които се
                    дължат силно изявените му дерматотонично, отводняващо и
                    противомикробно действие.
                  </p>
                  <p>
                    Екстрактът от репей е богат на активни компоненти, които
                    ускоряват растежа на нови клетки в организма, в това число и
                    на здрави кожни клетки. Това им действие се дължи основно на
                    съдържащия се в тях инулин. Освен това те действат
                    противоалергично, антисептично и епителизиращо.
                  </p>
                  <p>
                    Екстрактът от коприва включва дъбилни и белтъчни вещества,
                    поради което той упражнява дерматотоничен, противоалергичен,
                    успокояващ, противосърбежен и антисклеротичен ефект.
                  </p>
                  <p>
                    От своя страна екстрактът от камшик също действа
                    противовъзпалително и притежава противомикробни свойства.
                  </p>
                  <p>
                    <a href="https://vitaherb.bg/leaflets/Psoriazal_Plus_Listovka.pdf">
                      Листовка на продукта
                    </a>
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default PsoriazalPlusPage
